import { Typography } from "@mui/material";
import { Container } from "@mui/material";
import React from "react";
import LandingNavbar from "../../../components/navbar/LandingNavbar";
import { CopyBlock, dracula } from "react-code-blocks";
import Footer from "../../../components/Footer";
import SEO from "../../../SEO";

const Records = () => {

    const codeWithoutRecord = 
    `
    class Person {
        private final int age;
        private final String name;
    
        public Person(int age, String name) {
            this.age = age;
            this.name = name;
        }
    
        public int getAge() {
            return age;
        }
    
        public String getName() {
            return name;
        }
    
        @Override
        public String toString() {
            return "Person{" + "age=" + age + ", name=" + name + '}';
        }
    }
    
    
    public class Main {
        public static void main(String[] args) {
            Person p1 = new Person(16,"Daniel");
            System.out.println(p1);
        }
    }
    `
    const codeWithRecord = 
    `record Person(int age, String name){ }

    public class Main {
        public static void main(String[] args) {
            Person p1 = new Person(16,"Daniel");
            System.out.println(p1);
        }
    }`


    return ( 
        <div>
            <SEO
                title='Records in java'
                description='Record keyword in java'
                type='article' 
            />
            <Container sx={{ mt: 5 }} maxWidth="md">
                <Typography variant="h3" component="h1" sx={{ fontWeight: 'bold' }}>
                    Record type in Java
                </Typography>

                <br/>
                <br/>

                <Typography variant="h6">
                    Records were introduced in Java 14. It is mainly used for creating data transfer objects.
                </Typography>

                <br/>
                <br/>

                <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                    Why do we need records?
                </Typography>

                <br/>

                <Typography variant="h6">
                Many times we require an immutable class for holding data. This object is called data transfer object. Before Java 14, creating a data transfer object involved a lot of boilerplate code. This code included getters, constructors and overriding toString methods.
                </Typography>

                <br/>

                <Typography variant="h6">
                    Consider below code example:
                </Typography>

                <br/>

                <CopyBlock
                    language="java"
                    text={codeWithoutRecord}
                    showLineNumbers={false}
                    theme={dracula}
                    wrapLines={true}
                    codeBlock
                />

                <br/>

                <Typography variant="h6">
                Output:
                </Typography>

                <br/>

                <CopyBlock
                    language="java"
                    text={`Person[age=16, name=Daniel]`}
                    showLineNumbers={false}
                    theme={dracula}
                    wrapLines={true}
                    codeBlock
                />

                <br/>

                <Typography variant="h6">
                The declaration of all the final variables and constructors has required almost 25 lines of code. This has got developers thinking of something simpler to declare a data transfer class. That is when records were introduced.
                </Typography>

                <br/>
                <br/>

                <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                How is record implemented?
                </Typography>

                <br/>
                <Typography variant="h6">
                Lets simplify the code to use record instead of class.
                </Typography>

                <br/>

                <CopyBlock
                    language="java"
                    text={codeWithRecord}
                    showLineNumbers={false}
                    theme={dracula}
                    wrapLines={true}
                    codeBlock
                />

                <br/>

                <Typography variant="h6">
                Our 25 lines of code is reduced to a single line. Wasn't that amazing!
                </Typography>

                <br/>

                <Typography variant="h6">
                By default, records have the following characteristics:
                <ul>
                    <li>Records are final and cannot be subclassed.</li>
                    <li>All variables in record are treated as private and final</li>
                    <li>Records have a toString, hashCode, and equals method generated automatically based on their component fields.</li>
                    <li>Records have a constructor with parameters for each component, in the order they were declared.</li>
                </ul>
                </Typography>     

                <Typography variant="h6">
                Records are recommended to be used when we don't wish to modify the data members and use them purely as a data object. Records are a useful addition to Java for defining simple data classes, and can help to reduce boilerplate code and improve code readability. However, they are still an experimental feature and may change in future versions of Java.
                </Typography>           
            </Container>
        </div>
     );
}
 
export default Records;