import { createTheme, responsiveFontSizes } from '@mui/material/styles'

// Colors
import {
  backgroundGray,
  blackText,
  grayLighter1,
  grayLighter5,
  info,
  lightViolet,
  white,
  whiteText,
  lightBlack
} from './styleresources/Colors'

let Theme = createTheme({
  palette: {
    background: {
      default: backgroundGray,
    },
    primary: {
      main: lightBlack,
      contrastText: blackText,
    },
    secondary: {
      main: grayLighter1,
      contrastText: blackText,
    },
    info: {
      main: info,
    },
  },
  typography: {
    fontFamily: 'Mulish',
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          backgroundColor: white,
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'primary' },
          style: {
            backgroundColor: lightViolet,
            color: whiteText,
            ':hover': {
              backgroundColor: lightViolet,
              boxShadow: `0px 4px 11px ${grayLighter1}`,
            },
          },
        },
        {
          props: { variant: 'secondary' },
          style: {
            background: grayLighter1,
            color: blackText,
            ':hover': {
              backgroundColor: grayLighter1,
              boxShadow: `0px 4px 11px ${grayLighter5}`,
            },
          },
        },
      ],
    },
  },
})

Theme.props = {
  MuiButton: {
    variant: 'contained',
    size: 'large',
    fontFamily: 'Mulish',
  },

  MuiTextField: {
    fullWidth: true,
    InputProps: {
      style: {
        fontFamily: 'Mulish',
      },
    },
    InputLabelProps: {
      style: {
        fontFamily: 'Mulish',
      },
    },
  },

  MuiFormControlLabel: {
    style: {
      fontFamily: 'Mulish',
    },
  },

  MuiChip: {
    variant: 'outlined',
    color: 'primary',
    label: 'Chip Example',
  },

  MuiTabs: {
    variant: 'fullWidth',
  },

  MuiSnackbar: {
    style: {
      top: '8em',
      right: 0,
      height: '10%',
    },
  },

  MuiCard: {
    style: {
      borderRadius: 20,
    },
  },

  MuiContainer:{
    maxWidth:'md',
  }
}

Theme.overrides = {
  MuiButton: {
    root: {
      fontFamily: 'Mulish',
      fontWeight: '700',
    },
  },
  MuiTypography: {
    h2: {
      fontFamily: 'Mulish',
    },
    caption: {
      color: 'red',
    },
  },
  MuiChip: {
    root: {
      fontFamily: 'Mulish',
      fontWeight: 'bold',
      fontSize: '12pt',
      color: blackText,
      padding: 4,
      marginLeft: '2pt',
      marginTop: '3pt',
    },
  },
  MuiAvatar: {
    root: {
      width: Theme.spacing(20),
      height: Theme.spacing(20),
    },
  },
  MuiTab: {
    root: {
      fontSize: '14pt',
      fontFamily: 'Mulish',
      alignContent: 'space-between',
      fontWeight: 700,
    },
  },
  MuiTabs: {
    root: {
      color: blackText,
      textTransform: 'capitalize',
    },
  },
  MuiStep: {
    alternativeLabel: {
      margin: 1,
      padding: 1,
    },
  },
}

Theme = responsiveFontSizes(Theme)

export default Theme
