import { Avatar, Box, Button, Card, CardContent, CardMedia, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import SEO from '../SEO';
import { blackText } from '../styleresources/Colors';
import CodingImage from '../assets/landingPage/Coding-amico.svg'
import JavaLogo from '../assets/logo/java-logo.png'
import DSALogo from '../assets/logo/dsa-logo.png'
import ToolsLogo from '../assets/logo/tools-logo.png'
import { Link } from 'react-router-dom';

const LandingPage = () => {
    const content = [
        {
          id: 1,
          title: 'Java',
          image: JavaLogo,
          link: '/java'
        },
        {
          id: 2,
          title: 'Data Structures and Algorithms',
          image: DSALogo,
          link: '/data-structures'
        },
        {
          id: 3,
          title: 'Automation Tools',
          image: ToolsLogo,
          link: '/tools'
        },
      ]

    return ( 
        <Box>
        <SEO
            title='LetsCodeJava'
            description='Website to learn and improve your technical software development skills'
        />
        <Container maxWidth="xl">
        <Box>
          <Grid container alignItems={'center'} sx={{ minHeight: '600px' }}>
            {/* Left Section */}
            <Grid item xs={12} md={6}>
              <Box sx={{ mt: 5, textAlign: { xs: 'center', md: 'left' } }}>
                <Typography
                  variant="h6"
                  className="gradient-titleText"
                  // style={{
                  //   background: `linear-gradient(to right, ${primary},${secondary})`,
                  // }}
                  sx={{ mb: 5, fontWeight: 'bold' }}
                >
                  LEVEL UP YOUR CODING SKILLS
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    mb: 7,
                    color: blackText,
                    fontWeight: 'bold',
                    maxWidth: { md: '650px' },
                    textAlign: { xs: 'center', md: 'left' },
                  }}
                >
                  LETS CODE JAVA is a resource for programmers and developers, 
                  offering a variety of articles on topics such as Java, React, and data structures. The 
                  site covers in-depth coding problems, frontend concepts in React and JavaScript, and online tools 
                  for automating manual tasks. The goal of the site is to help users improve their coding skills and prepare for interviews.
                </Typography>
              </Box>
            </Grid>
            {/* Right Section */}
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                p: 3,
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <img src={CodingImage} alt="its something" />
            </Grid>
          </Grid>
        </Box>

        <Typography
            variant="h4"
            sx={{ mb: 1, fontWeight: 'bold', textAlign: 'center' }}
          >
            Get Started 🎯
        </Typography>

        <Grid
            container
            style={{ display: 'flex', justifyContent: 'center' }}
            spacing={3}
            sx={{ mt: 4 }}
          >
            {content.map((item, index) => (
              <Grid item key={index} xs={12} sm={6} md={3}>
              <Link to={item.link} style={{ textDecoration: 'none' }}>
                <Card
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    textAlign: 'center',
                    width: '80%',
                    height: '100%',
                    margin: '5px',
                  }}
                  elevation={4}
                >
                  <CardContent>
                    <Avatar
                      variant="square"
                      sx={{
                        width: '160px',
                        height: '160px',
                        backgroundColor: 'transparent',
                        margin: 'auto',
                      }}
                    >
                      <img
                        alt={'PUBLIC_URL'}
                        src={item.image}
                        style={{ width: '130px', height: '130px' }}
                      />
                    </Avatar>
                    <Typography varient="h6" sx={{ mt: 2, fontWeight: 'bold' }}>
                      {item.title}
                    </Typography>

                  </CardContent>
                </Card>
                </Link>
              </Grid>
            ))}
          </Grid>
      </Container>
        </Box>
     );
}
 
export default LandingPage;