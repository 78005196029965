import { Container, Grid, Typography } from "@mui/material";
import React from "react";
import { grayLighter3 } from "../styleresources/Colors";

const Footer = () => {
    return ( 
        <div style={{backgroundColor: grayLighter3}}>
        <Container sx={{mt:10, pb:2}}>
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={6} md={3}>
                <Typography
                variant="body1"
                sx={{ fontWeight: 'bold', cursor: 'pointer' }}
                >
                Terms of Use
                </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
                <Typography
                variant="body1"
                sx={{ fontWeight: 'bold', cursor: 'pointer' }}
                >
                Privacy Policy
                </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <Typography
                variant="body1"
                sx={{ fontWeight: 'bold', cursor: 'pointer' }}
                >
                Copyright © 2023 LetsCodeJava All Rights Reserved.
                </Typography>
            </Grid>
        </Grid>
        </Container>
        </div>
     );
}
 
export default Footer;