import { Container, Typography } from "@mui/material";

const ReverseArray = () => {
    return ( 
        <div>
        <Container sx={{ mt: 5 }} maxWidth="md">
        <Typography variant="h3" component="h1" sx={{ fontWeight: 'bold' }}>
            Program to reverse an array or string
        </Typography>
            In progress. Coming soon.
        </Container>
        </div>
     );
}
 
export default ReverseArray;