import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { white } from '../styleresources/Colors';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';

export default function MenuDropdown({item}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant="contained"
        size={'large'}
        style={{
            color: white,
            marginRight: '40px',
            width: '120px', 
            height: '40px',
            fontWeight: '500',
            letterSpacing: '2px',
        }}
        disableElevation
      >
        {item.title}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
      {item.value.map((item, index) => (
        <MenuItem key={index} onClick={handleClose}>
          <Link to={item.nav} style={{ textDecoration: 'none' }}> {item.option} </Link>
        </MenuItem>
      ))}
        
      </Menu>
    </Box>
    
  );
}