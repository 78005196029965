import { Grid, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import React from "react";
import LandingNavbar from "../../components/navbar/LandingNavbar";
import FolderRoundedIcon from '@mui/icons-material/FolderRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { JAVA_RECORD } from "../../constants/Urls";
import { Link } from "react-router-dom";
import SEO from "../../SEO";

const JavaHome = () => {

    return (
        <div>
        <SEO
            title='Java'
            description='java tutorial'
            type='article' 
        />
        <Container sx={{ mt: 2}}>
            <Grid container alignItems={'center'} spacing={2}>
                <Grid item>
                    <FolderRoundedIcon 
                    style={{
                            width: '60px',
                            height: '60px',
                        }}/>
                </Grid>
                <Grid item>
                    <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                    Java Tutorials
                    </Typography>
                </Grid>
            </Grid>

            <Grid container alignItems={'center'} spacing={2} sx={{mt:2}}>
                <Grid item>
                    <ArrowForwardIosRoundedIcon fontSize="medium"/>
                </Grid>
                <Grid item>
                    <Typography variant="h6" sx={{cursor: 'pointer', color:'blue' }}>
                    <Link to={JAVA_RECORD}>Java 14 - Record Keyword</Link>
                    </Typography>
                </Grid>
            </Grid>
        </Container>
        </div>
     );
}
 
export default JavaHome;