import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { ARRAY_HOME, DSA_HOME, JAVA_HOME, LANDING_PAGE_URL, TOOLS_HOME } from '../../constants/Urls'
import MenuDropdown from '../MenuDropdown'

const LandingNavbar = () => {

  const options = [
    
    {
        title: "Java",
        value: [
          {
            id:1,
            option:'Java 8 Tutorial',
            nav: JAVA_HOME
          },
          // {
          //   id:2,
          //   option:'Spring Boot',
          //   nav: LANDING_PAGE_URL
          // }
        ]
    },
    {
      title: "Data Structures",
      value: [
        {
          id:1,
          option:'Array',
          nav: DSA_HOME
        },
        // {
        //   id:2,
        //   option:'Linked List',
        //   nav: DSA_HOME
        // }
      ]
    },
    {
      title: "Converter tools",
      value: [
        {
          id:1,
          option:'Tools',
          nav: TOOLS_HOME
        },
        // {
        //   id:2,
        //   option:'Linked List',
        //   nav: DSA_HOME
        // }
      ]
    }
]
  

  return (
    <AppBar position="static" color="primary" sx={{ zIndex: 1000 }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h5"
            noWrap
            component="a"
            fontFamily="Orbitron"
            href="/"
            sx={{
              display: { xs: 'none', md: 'flex' },
              fontWeight: 700,
              letterSpacing: '.1rem',
              color: 'white',
              textDecoration: 'none',
            }}
          >
            Lets Code Java
          </Typography>

          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            fontFamily="Orbitron"
            sx={{
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontWeight: 700,
              fontSize: "1.1rem",
              letterSpacing: ".1rem",
              color: "white",
              textDecoration: "none",
              justifyContent: { xs: "center", sm: "left" },
            }}
          >
            Lets Code Java
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} />

          {options.map((item, index) => (
          <MenuDropdown item={item} key={index}/>
          ))}
        </Toolbar>
      </Container>
    </AppBar>
  )
}
export default LandingNavbar
