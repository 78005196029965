import { Box, Button, Grid, Typography } from '@mui/material';
import { Container, Stack } from '@mui/system';
import React, { useState } from 'react';
import ReactQuill, {Quill} from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import './style.css'
import { grayLighter3 } from '../../../styleresources/Colors';
import LandingNavbar from '../../../components/navbar/LandingNavbar';
import SEO from '../../../SEO';

const CustomToolbar = () => (
  <div id="toolbar">
    <button className="ql-bold"/>
    <button className="ql-italic" />
    <button className="ql-strike" />
    <select className="ql-font">
      <option value="monospace" defaultValue={"monospace"}>
        Monospace
      </option>
    </select>
  </div>
);


const Font = Quill.import("formats/font");
Font.whitelist = [
  "monospace"
];
Quill.register(Font, true);


export default function Formatter() {
  const [text, setText] = useState('This is an <b>bold</b> and <i>italic</i> sample text. Edit here');
  const [output, setOutput] = useState('This is an *bold* and _italic_ sample text. Edit here');

  const handleFormat = () => {
    // WhatsApp uses * for bold and _ for italic
    const boldRegex = /<strong(?: class="[^"]+")?>([^<]+)<\/strong>/g;
    const italicRegex = /<em>([^<]+)<\/em>/g;
    const strikeThroughRegex = /<s>([^<]+)<\/s>/g;
    const monospaceRegex = /<(?:[^>=]+)(?:class="ql-font-monospace")[^>]*>([^<]+)<\/[^>]+>/g;

    let formattedText = text.trim().replace(strikeThroughRegex, '~$1~');
    formattedText = formattedText.replace(italicRegex, '_$1_');
    formattedText = formattedText.replace(monospaceRegex, '```$1```');
    formattedText = formattedText.replace(boldRegex, '*$1*');

    setOutput(formattedText)
  };

  let formats = ["bold","italic","strike","font"]

  let modules = {
    toolbar: {
      container: "#toolbar",
    }
  };

  return (
    <div>
    <SEO
        title='WhatsApp Text Formatter'
        description='This is a tool that allows you to add WhatsApp-style formatting to your text, such as bold, italic, strikethrough, and monospace. Simply paste your text into the input field, apply the desired formatting, and then paste the output into WhatsApp.'
        type='article' 
    />
    <Container sx={{ mt: 5 }} maxWidth="md">
    <Grid container alignItems={'center'}>
        <Grid item xs={3} md={1}>
            <WhatsAppIcon 
            style={{
                    width: '60px',
                    height: '60px',
                }}/>
        </Grid>
        <Grid item xs={9} md={11}>
            <Typography variant="h3" component="h1" sx={{ fontWeight: 'bold' }}>
            WhatsApp Text Formatter
            </Typography>
        </Grid>
    </Grid>

    <br/>
    <br/>

    <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
        WhatsApp allows formatting for these 4 types:
    </Typography>

    <br/>

    <Typography variant="h6">
    <ul>
      <li>
        <b>Bold:</b> To convert words into <b>bold</b>, wrap the words in *some text*
      </li>
      <br/>

      <li>
        <i>Italic:</i> To convert words into <i>italic</i>, wrap the words in _some text_
      </li>

      <br/>

      <li>
        <s>Strikethrough:</s> To convert words into <s>strikethrough</s>, wrap the words in ~some text~
      </li>

      <br/>

      <li>
        <span className='ql-font-monospace'>Monospace:</span> To convert words into <span className='ql-font-monospace'>monospace</span>, wrap the words in ```some text```
      </li>
    </ul>
    </Typography>
    <br/>

    <Typography variant="h6">
    Here is a converter to automatically make these changes for you. You can either paste your text or manually type it and edit the format to bold, italic, strikethrough or monospace.
    </Typography>

    <br/>

    <Stack className="text-editor" direction={'column'} spacing={5} style={{ minHeight: '100vh' }}>
      <div className="text-editor" style={{width:"100%"}}>
        <CustomToolbar/>
        <ReactQuill theme="snow" value={text} onChange={setText} modules={modules} formats={formats} style={{height: '20vh', backgroundColor: grayLighter3}} placeholder="Paste here or type text..."/> 
      </div>
      <div style={{alignSelf:'center'}}>
        <Button variant="primary" onClick={handleFormat}>Format</Button>
      </div>
      <div width={'100%'}>
        <Typography variant="h5">Click FORMAT to generate output. You can copy and paste in WhatsApp 🔥</Typography>
        <br/>
        <Box className="content" dangerouslySetInnerHTML={{__html: output}} sx={{ border: '1px dashed grey', minHeight: '5vh', padding:5, backgroundColor: grayLighter3 }}></Box>
      </div>
    </Stack>
    </Container>
    </div>
  )
}