export const LANDING_PAGE_URL = '/'

//section java
export const JAVA_HOME = '/java'

//java articles
export const JAVA_RECORD = '/java-record'


//Data Structures and Algorithms
export const DSA_HOME = '/data-structures'

//Array
export const ARRAY_HOME = '/data-structures/array'


//articles
export const REVERSE_ARRAY = '/program-to-reverse-array-or-string'

//tools
export const TOOLS_HOME = '/tools'

//whatsapp-formatter
export const WHATSAPP_FORMATTER = '/whatsapp-text-formatter'
