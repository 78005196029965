import ThemeProvider from '@mui/material/styles/ThemeProvider'
import { CssBaseline } from '@mui/material'
import Theme from './Theme'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import {
  DSA_HOME,
  JAVA_HOME,
  JAVA_RECORD,
  LANDING_PAGE_URL,
  REVERSE_ARRAY,
  TOOLS_HOME,
  WHATSAPP_FORMATTER
} from './constants/Urls'
import LandingPage from './pages/LandingPage'
import JavaHome from './pages/homepages/JavaHome'
import Records from './pages/blogs/java/Records'
import ReactGA from 'react-ga';
import { useEffect } from 'react'
import DSAHome from './pages/homepages/DSAHome'
import Formatter from './pages/tools/WhatsAppFormatter/Formatter'
import Footer from './components/Footer'
import LandingNavbar from './components/navbar/LandingNavbar'
import ToolsHome from './pages/homepages/ToolsHome'
import ReverseArray from './pages/blogs/dsa/array/ReverseArray'
const TRACKING_ID = "UA-253429337-1";
ReactGA.initialize(TRACKING_ID);

function App() {

  useEffect(() => {
    if(window.location.hostname !== "localhost"){
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, []);

  return (
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      <BrowserRouter>
      <LandingNavbar/>
        <Routes>

          {/* Landing Page */}
          <Route path={LANDING_PAGE_URL} element={<LandingPage />} />

          {/* Home page for java */}
          <Route path={JAVA_HOME} element={<JavaHome />} />

          {/* under java - record type*/}
          <Route path={JAVA_RECORD} element={<Records />} />



          {/* Home page for data structures */}
          <Route path={DSA_HOME} element={<DSAHome />} />

          <Route path={REVERSE_ARRAY} element={<ReverseArray />} />

          {/* Home page for tools */}
          <Route path={TOOLS_HOME} element={<ToolsHome />} />

          {/* whatsapp text formatter tool */}
          <Route path={WHATSAPP_FORMATTER} element={<Formatter />} />

        </Routes>
      <Footer/>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
