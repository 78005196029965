// Main Colors
export const primary = '#f37920'
export const secondary = '#F9A825'
export const secondaryLight = '#6ddbff'
export const info = '#00ff00'
export const white = '#fff'
export const black = 'black'
export const blue = '#4488eb'
export const violet = '#4F57EC'
export const yellow = '#FFEE58'
export const lightViolet = '#a100ff'
export const lightPink = '#CB50B6'

// Black Color Variants
export const lightBlack = '#2C2D31'
export const lightBlackHover = '#383a40'
export const backgroundGray = '#f6f6f6'
export const darkGrey = '#191C24'
export const readBlack = '#212427'

// Gray Color Variants
export const grayLighter0 = '#7E7E7E'
export const grayLighter = '#aaa'
export const grayLighter1 = '#c9c9c9'
export const grayLighter2 = '#ddd'
export const grayLighter3 = '#e9e9e9'
export const grayLighter4 = '#f4f4f4'
export const grayLighter5 = '#f9f9f9'
export const fieldOutlineColor = '#c4c4c4'

// Blue
export const blue1 = '#3e6bd4'
export const journeysButton = '#196BB6'
export const landingSectionBackground = '#F8F2FA'
export const landingCardBackground = '#EDDBF5'

// Text Colors
export const whiteText = '#fefefe'
export const blackText = '#232323'
export const greenText = '#5fd46d'

// Alert Colors
export const warning = '#F68A1C'
export const success = '#4D9A51'
export const error = '#d32f2f'
